<template>
	<div class="page" style="background-color: #EDEDED;">
		<div class="container">
			<div class="notes">
				<h1>请进行实名认证</h1>
				<h4>为落实中国政府新冠防疫要求，大会采用实名制入场，所有观众必须进行实名登记，谢谢配合!</h4>
			</div>
			<div class="content">
				<van-dropdown-menu active-color="#333333" class="input radius">
				  <van-dropdown-item v-model="cardType" :options="options" :title="cartName || '请选择您的证件类型'" @change="getCertType" />
				</van-dropdown-menu>
				<div class="cert-info">
					<div v-if="cardType && cardType == 1" style="width: 100%;">
						<div class="label">请输入身份证姓名：</div>
						<van-field ref="idCardName" v-model="idCardName" class="input font14 radius" />
						<div class="label">请输入身份证号码：</div>
						<van-field ref="idCardCode" readonly clickable :value="idCardCode" @touchstart.native.stop="isShowKeyboard = true" class="input font14 radius" style="margin-bottom: 0;" />
						<!-- 身份证号小键盘 -->
						<van-number-keyboard v-model="idCardCode" extra-key="X" close-button-text="关闭" :show="isShowKeyboard" :maxlength="18" @blur="isShowKeyboard = false" />
					</div>
					<div v-if="cardType && cardType != 1" class="cert-info bg-white radius" @click="shootStart">
						<img v-if="cardPath" :src="cardPath" class="fill img" />
						<template v-else>
							<van-icon name="add-o" :color="style.btnColor || btnColor" size="0.8rem" style="margin: 8px; auto" />
							<div class="text-center">
								点击拍照上传证件<br>人像正面照片
							</div>
						</template>
						
						<div v-if="cardPath && isReUpload" class="re-upload fill">
							<van-icon name="add-o" color="#fff" size="0.8rem" style="margin-bottom: 10px" />
							<div class="text-center">点击重新拍摄</div>
						</div>
					</div>
				</div>
				<van-button type="primary" block :color="style.btnColor || btnColor" class="next-btn radius" @click="nextStep">下一步</van-button>
			</div>
		</div>
		
		<input v-show="false" type="file" id="fileElem" accept="image/*" ref="imgFile" @change="chooseImg">
		
		<div class="footer">
			<bottom :mode="style.mode" :level="style.level"></bottom>
		</div>
	</div>
</template>

<script>


	import commonMixin from '@/view/process/mixin/common.js';
	import realnameAuthMixin from '@/view/process/mixin/realnameAuth.js';

	export default {
		mixins: [commonMixin, realnameAuthMixin],
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('zh'); // 设置语言环境
			this.options = this.certtypes.zh;
		},

		methods: {
			
		},
	}
</script>

<style lang="less" scoped>
	@color: #9F9D9D;

	.notes {
		width: 280px;
		margin: 10vh auto 8vh;

		h1,
		h4 {
			text-align: center;
			color: @color;
		}

		h1 {
			font-size: 24px;
			margin-bottom: 16px;
		}

		h4 {
			font-size: 13px;
			text-align: left;
			line-height: 20px;
		}
	}
	
	.bg-white {
		background-color: #fff;
	}

	.content {
		width: 290px;
		margin: 0 auto;
		color: @color;
		font-size: 14px;

		.label {
			margin-bottom: 1.4vh;
			padding-left: 10px;
		}

		.input {
			margin-bottom: 4vh;
		}
	
		.cert-info {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 180px;
			line-height: 1.8em;
			color: #333;
			font-size: 14px;
			cursor: pointer;
		}
		
		/deep/ .van-dropdown-menu {
			
			.van-overlay {
				background-color: rgba(0,0,0,0);
			}
			.van-dropdown-menu__bar {
			  box-shadow: none;
			}
			.van-dropdown-menu__item {
				justify-content: flex-start;
			}
			.van-dropdown-menu__title {
				width: 260px;
				font-size: 14px;
				padding: 0 10px 0 16px;
			}
			.van-dropdown-menu__title::after {
				top: 25%;
			  right: -20px;
				border: 6px solid;
				border-color: transparent transparent #888 #888;
			}
			.van-dropdown-item {
				width: 290px;
				left: 43px;
			}
			.van-dropdown-item__option .van-cell__value {
				display: none;
			}
		}

	}

	.next-btn {
		margin-top: 6vh;
		letter-spacing: 1px;
		font-size: 16px;
	}
	
	.re-upload {
	  position: absolute;
	  left: 0;
	  top: 0;
	  background-color: rgba(0, 0, 0, .4);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;
		letter-spacing: 1px;
	}
	
</style>
